  <!-- <a (click)="logout();" class="btn-stack ">
                    Cerrar Sesión
                </a> -->
  <!-- <div class="col-md-3 text-center"> 
                        <img *ngIf="!doc1"src="/assets/images/sorteo2022/form/pdf.png" class="img-fluid">
                        <iframe  *ngIf="doc1" [src]="sanitizer.bypassSecurityTrustResourceUrl(selectedPdf1)" width="130px" height="130px" scrolling="no" style="overflow:hidden"></iframe>

                    </div> -->
  <section class="banner-docs">
      <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                  <h1>Documentación</h1>
              </div>
          </div>
      </div>
  </section>


  <section class="content-docs">
      <div class="container">
          <div class="row">
              <div class="col-md-12">
                  <nav>
                      <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                          <a class="nav-item nav-link btn-stack  active" id="nav-home-tab" data-toggle="tab"
                              href="#nav-archivos" role="tab" aria-controls="nav-home" aria-selected="true">
                              Envio de archivos
                          </a>
                          <a class="nav-item btn-stack  nav-link" id="nav-profile-tab" data-toggle="tab"
                              href="#nav-archivosenviados" role="tab" aria-controls="nav-profile" aria-selected="false"
                              (click)="getDoc()">
                              Archivos enviados
                          </a>

                      </div>
                  </nav>
                  <div class="tab-content " id="nav-tabContent">
                      <div class="tab-pane fade show active table-responsive" id="nav-archivos" role="tabpanel"
                          aria-labelledby="nav-home-tab ">
                          <div class="container mt-4">
                            <div id="accordion">
                                <!-- Documentos Ganadores Directos -->
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                Documentos Ganadores Directos
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Identificación</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                  
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Comprobante de domicilio</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 2)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(2, 'COMP_DOMICILIO');"
                                                        [className]="!archivos[2] || flags[2] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[2]">
                  
                                                        <span *ngIf="docs[2].send ">Enviado</span>
                                                        <span *ngIf=" !docs[2].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Curp</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 3)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(3, 'CURP');"
                                                        [className]="!archivos[3] || flags[3] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[3]">
                  
                                                        <span *ngIf="docs[3].send ">Enviado</span>
                                                        <span *ngIf=" !docs[3].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                  
                                        </div>
                                    </div>
                                </div>
                  
                                <!-- Documentos Ganadores Directos Menor de Edad -->
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                Documentos Ganadores menor de edad
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Identificación escolar del menor</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                  
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Acta de nacimiento</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 5)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(5, 'ACTA_NACIMIENTO');"
                                                        [className]="!archivos[5] || flags[5] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[5]">
                  
                                                        <span *ngIf="docs[5].send ">Enviado</span>
                                                        <span *ngIf=" !docs[5].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Acta de matrimonio de padres</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 7)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(7, 'ACTA_MATRIMONIO_PAPAS');"
                                                        [className]="!archivos[7] || flags[7] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[7]">
                  
                                                        <span *ngIf="docs[7].send ">Enviado</span>
                                                        <span *ngIf=" !docs[7].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Identificación del padre o tutor</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 8)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(8, 'ID_PAPA_TUTOR');"
                                                        [className]="!archivos[8] || flags[8] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[8]">
                  
                                                        <span *ngIf="docs[8].send ">Enviado</span>
                                                        <span *ngIf=" !docs[8].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Comprobante de domicilio</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 2)"
                                                    accept="application/pdf" required />+
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(2, 'COMP_DOMICILIO');"
                                                        [className]="!archivos[2] || flags[2] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[2]">
                  
                                                        <span *ngIf="docs[2].send ">Enviado</span>
                                                        <span *ngIf=" !docs[2].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="constanciaFiscalInput">Constancia de Situación Fiscal</label>
                                                <input id="constanciaFiscalInput" type="file" class="form-control"
                                                    (change)="capturarFile($event, 4)" accept="application/pdf" required />
                  
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(4, 'RFC_DOCUMENTO');"
                                                        [className]="!archivos[4] || flags[4] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[4]">
                  
                                                        <span *ngIf="docs[4].send ">Enviado</span>
                                                        <span *ngIf=" !docs[4].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                  
                                <!-- Documentos Ganadores Directos Extranjeros -->
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree"
                                                aria-expanded="false" aria-controls="collapseThree">
                                                Documentos Ganadores Directos Extranjeros
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>ID / pasaporte (anexar ambos en el mismo documento)</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                  
                  
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                  
                                <!-- Casos Especiales -->
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour"
                                                aria-expanded="false" aria-controls="collapseFour">
                                                Casos Especiales
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div class="card-body">
                                            <ul>
                                                <li>
                                                    Si el ganador directo no puede acudir por el premio personalmente: deberá de
                                                    redactar una carta poder firmada preferentemente en tinta azul acompañada de las
                                                    identificaciones de quien otorga el poder, de quien recibe el poder y de los dos
                                                    testigos.
                                                    Deberá ser entregada de forma física en la entrega del premio.
                  
                                                </li>
                                                <li>
                                                    Si el ganador directo lamentablemente falleció: el familiar deberá presentar acta de
                                                    defunción original y documento que avale sesión de derechos por parte del finado para
                                                    la recepción del premio.
                                                </li>
                  
                                            </ul>
                                            <p>
                                                Para una situación en lo en particular favor comunicarse directamente vía correo
                                                electrónico a la Gerencia de Control: dante.cortes@anahuac.mx , jaime.mucinoco@anahuac.mx
                                            </p>
                                            <!-- <div class="form-group">
                                                <label>Carta poder</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 9)"
                                                    accept="application/pdf" />
                                            </div>
                                            <div class="form-group">
                                                <label>Identificación de quien recibe carta poder</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 10)"
                                                    accept="application/pdf" />
                                            </div>
                                            <div class="form-group">
                                                <label>Identificación de quien otorga carta poder</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 11)"
                                                    accept="application/pdf" />
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                  
                  
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFive"
                                                aria-expanded="false" aria-controls="collapseFive">
                                                Documentos ganadores colaborador
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Identificación</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                  
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Comprobante de domicilio</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 2)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(2, 'COMP_DOMICILIO');"
                                                        [className]="!archivos[2] || flags[2] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[2]">
                  
                                                        <span *ngIf="docs[2].send ">Enviado</span>
                                                        <span *ngIf=" !docs[2].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Curp</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 3)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(3, 'CURP');"
                                                        [className]="!archivos[3] || flags[3] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[3]">
                  
                                                        <span *ngIf="docs[3].send ">Enviado</span>
                                                        <span *ngIf=" !docs[3].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="constanciaFiscalInput">Constancia de Situación Fiscal</label>
                                                <input id="constanciaFiscalInput" type="file" class="form-control"
                                                    (change)="capturarFile($event, 4)" accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(4, 'RFC_DOCUMENTO');"
                                                        [className]="!archivos[4] || flags[4] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[4]">
                  
                                                        <span *ngIf="docs[4].send ">Enviado</span>
                                                        <span *ngIf=" !docs[4].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                  
                                            <div class="form-group">
                                                <label for="estadoCuentaInput">Carátula del Estado de Cuenta Bancario</label>
                                                <input id="estadoCuentaInput" type="file" class="form-control"
                                                    (change)="capturarFile($event, 6)" accept="application/pdf" required />
                  
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(6, 'NUMERO_CTA_BANCARIO');"
                                                        [className]="!archivos[6] || flags[6] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[6]">
                  
                                                        <span *ngIf="docs[6].send ">Enviado</span>
                                                        <span *ngIf=" !docs[6].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                  
                                        </div>
                                    </div>
                                </div>
                  
                                <!-- DOCUMENTOS GANADORES DE COLABORADOR MENOR DE EDAD -->
                                <div class="card">
                                    <div class="card-header" id="headingSix">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix"
                                                aria-expanded="false" aria-controls="collapseSix">
                                                Documentos Ganadores menor de edad
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label>Identificación escolar del menor</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Acta de nacimiento</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 5)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(5, 'ACTA_NACIMIENTO');"
                                                        [className]="!archivos[5] || flags[5] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[5]">
                  
                                                        <span *ngIf="docs[5].send ">Enviado</span>
                                                        <span *ngIf=" !docs[5].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Acta de matrimonio de padres</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 7)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(7, 'ACTA_MATRIMONIO_PAPAS');"
                                                        [className]="!archivos[7] || flags[7] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[7]">
                  
                                                        <span *ngIf="docs[7].send ">Enviado</span>
                                                        <span *ngIf=" !docs[7].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Identificación del padre o tutor</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 8)"
                                                    accept="application/pdf" />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(8, 'ID_PAPA_TUTOR');"
                                                        [className]="!archivos[8] || flags[8] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[8]">
                  
                                                        <span *ngIf="docs[8].send ">Enviado</span>
                                                        <span *ngIf=" !docs[8].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Comprobante de domicilio</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 2)"
                                                    accept="application/pdf" required />
                                            </div>
                                            <div class="form-group">
                                                <label for="constanciaFiscalInput">Constancia de Situación Fiscal</label>
                                                <input id="constanciaFiscalInput" type="file" class="form-control"
                                                    (change)="capturarFile($event, 4)" accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(2, 'COMP_DOMICILIO');"
                                                        [className]="!archivos[2] || flags[2] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[2]">
                  
                                                        <span *ngIf="docs[2].send ">Enviado</span>
                                                        <span *ngIf=" !docs[2].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                  
                  
                  
                                <!-- DOCUMENTOS GANADORES DE COLABORADOR EXTRANJEROS  -->
                                <div class="card">
                                    <div class="card-header" id="headingSeven">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven"
                                                aria-expanded="false" aria-controls="collapseSeven">
                                                Documentos de ganadores colaboradores extranjero
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                        <div class="card-body">
                  
                                            <div class="form-group">
                                                <label>ID / pasaporte (anexar ambos en el mismo documento)</label>
                                                <input type="file" class="form-control" (change)="capturarFile($event, 1)"
                                                    accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(1, 'IDENTIFICACION');"
                                                        [className]="!archivos[1] || flags[1] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[1]">
                  
                                                        <span *ngIf="docs[1].send ">Enviado</span>
                                                        <span *ngIf=" !docs[1].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label for="estadoCuentaInput">Carátula del Estado de Cuenta Bancario</label>
                                                <input id="estadoCuentaInput" type="file" class="form-control"
                                                    (change)="capturarFile($event, 6)" accept="application/pdf" required />
                                                <div class="p-2 m-2">
                                                    <button class="btn-stack " (click)="subirArchivo(6, 'NUMERO_CTA_BANCARIO');"
                                                        [className]="!archivos[6] || flags[6] ? 'disabled' : 'btn-docs '"
                                                        [disabled]="!archivos[6]">
                  
                                                        <span *ngIf="docs[6].send ">Enviado</span>
                                                        <span *ngIf=" !docs[6].send ">Enviar documento</span>
                                                    </button>
                                                </div>
                                            </div>
                  
                                        </div>
                                    </div>
                                </div>
                  
                  
                            </div>
                        </div>
                      </div>


                      <div class="tab-pane fade table-responsive" id="nav-archivosenviados" role="tabpanel"
                          aria-labelledby="nav-profile-tab">
                          <table class="table table-striped">
                              <thead>
                                  <tr>
                                      <th scope="col-4">DOCUMENTO</th>
                                      <th scope="col-4">ARCHIVO</th>
                                      <th scope="col-4">Estado archivo</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <!--documentos -->

                                  <tr *ngFor="let archivos of archivosNewEstruc; ">
                                      <th class="col-4">{{ archivos.nombreArchivo }}</th>
                                      <td class="col-4">
                                          <a (click)="descargarArchivo(archivos.ruta)" target="_blank">

                                              {{ archivos.archivo }}
                                          </a>
                                      </td>
                                      <td class="col-4">
                                          <ul class="list-unstyled multi-steps">
                                              <li
                                                  [ngClass]="{ 'completed': archivos.estadoArchivo >= 1,'': archivos.estadoArchivo <= 0}">
                                                  Envío de archivo
                                              </li>
                                              <li
                                                  [ngClass]="{ 'completed': archivos.estadoArchivo > 1,  'active': archivos.estadoArchivo == 1}">
                                                  Archivo recibido
                                              </li>
                                              <li
                                                  [ngClass]="{ 'completed': archivos.estadoArchivo >= 2,  'active': archivos.estadoArchivo == 2 }">
                                                  Archivo aprobado
                                              </li>
                                              <ng-container *ngIf=" archivos.estadoArchivo == -1">
                                                  <li class="error">Error</li>
                                              </ng-container>

                                          </ul>
                                          <!-- <img *ngIf="archivos.archivo;" src="/assets/images/sorteo2022/form/comprobado.png" width="30"> -->

                                      </td>
                                  </tr>



                              </tbody>

                          </table>
                          <div class="w-100 text-center">
                              <div *ngIf="flagArchives" class="spinner-border text-primary mt-2 mb-2" role="status"
                                  style="color: #f35f44 !important; margin: auto;">
                                  <span class="sr-only">Loading...</span>
                              </div>
                          </div>
                          <div *ngIf="flagError" class="alert alert-danger" role="alert">
                              Aún no has subido archivos.
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>
  </section>


>



  <!-- Modal send-->
  <div class="modal fade" id="modal-warning" tabindex="-1" role="dialog" aria-labelledby="modal-warning"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content p-4">


              <div class="modal-body text-center">
                  <h3>IMPORTANTE</h3>
                  <p>Los documentos que cuenten con un contorno rojo SON obligatorios.</p>
                  <form class="was-validated">
                      <input type="file" class="form-control" accept="application/pdf" required />
                  </form>

                  <p>Los documentos que no cuenten con un contorno rojo NO SON obligatorios.</p>
                  <input type="file" class="form-control" accept="application/pdf" disabled />
              </div>
              <div class="modal-footer text-center border-0 justify-content-center">
                  <a data-dismiss="modal" class="btn-stack  mb-">Cerrar</a>
              </div>
          </div>
      </div>
  </div>



  <div class="content-btn-login">
      <a (click)="logout()" class="btn-close-login">
          <span class="text">Cerrar Sesión</span>
      </a>
  </div>