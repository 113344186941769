import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-ganadoresmagno',
  templateUrl: './ganadoresmagno.component.html',
  styleUrls: ['./ganadoresmagno.component.scss']
})
export class GanadoresmagnoComponent implements OnInit {
  dataNuevos: any[] = [];
  filteredData: any[] = [];
  searchText: string = '';
  p: number = 1;

  constructor() {
    this.loadExcel();
  }

  ngOnInit(): void {}

  loadExcel() {
    const url = 'assets/pdf/ganadores-sam.xlsx';
    fetch(url)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const workbook = XLSX.read(buffer, { type: 'array' });

        const sheetNuevos = workbook.Sheets['participantes'];

        this.dataNuevos = XLSX.utils.sheet_to_json(sheetNuevos);

        // Inicializa el filtro con los datos de Nuevos
        this.filteredData = this.dataNuevos;
      })
      .catch(error => console.error('Error loading Excel file:', error));
  }

  filterData() {
    const term = this.searchText.trim().toLowerCase(); // Elimina espacios en blanco alrededor del término de búsqueda
    this.filteredData = this.dataNuevos.filter(participante => {
      const folio = participante.folio ? participante.folio.toString().toLowerCase() : ''; // Asegura que folio sea cadena
      const nombre = participante.nombre ? participante.nombre.toString().toLowerCase() : ''; // Asegura que nombre sea cadena
  
      // Filtrar si el término de búsqueda está en el folio o el nombre
      return folio.includes(term) || nombre.includes(term);
    });
  }
  
  
  getFirstName(fullName: string): string {
    if (!fullName || fullName === 'NULL') return 'FISICO';
    const nameParts = fullName.split(' ');
    return nameParts[0].toUpperCase();
  }
}
