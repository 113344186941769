import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { HomeServiceService } from 'src/services/home-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Boleto } from '../../../models/boleto';
import { Title, Meta } from '@angular/platform-browser';
import { timer } from 'rxjs';


@Component({
	selector: 'app-home', templateUrl: './home.component.html'
})

export class HomeComponent implements OnInit {
	public start: boolean = false; public boletos_generados: Array<Object> = [];
	public info_boleto: Array<Object> = [];
	public boleto_random: Object = {};
	public precio_boleto: number = 0;
	public boleto: Boleto;
	public tipos_pago: number = 1;
	public boleto_disponible: number = 1;
	public boletos: Array<Object> = [];
	public boleto_: Array<Object> = [];
	public boletosLuck: Array<Boleto> = [];
	public boletosLuckfil: Array<Boleto> = [];


	public metodo: boolean = false;
	public mensajeError: string = "";
	public boleto_check: boolean = false;
	checkbox: string = '';
	Error: boolean = false;
	ErrorSuerte: boolean = false;
	public popC: any;
	public popstate: any;
	public checkC: number = 0;
	public box: any;
	PopBoleto: boolean = false;
	contlck: number = 3;

	link;
	select = false;
	linkInput;
	session = false;
	mensaje = "";
	mensaje2 = "";
	idPayback = "";
	urlPB = "";
	linkPB = window.location.href;
	arrayPB = [];
	flag_buy = false;
	flag_buy_loader = false;
	flag_buy_btn = false;
	//meta
	title = 'Sorteo Anáhuac Novena Edición ¡Más de 850 premios para ti!';
	flagGirar = false;


	//time magno
	_second = 1000;
	_minute = this._second * 60;
	_hour = this._minute * 60;
	_day = this._hour * 24;
	end: any;
	now: any;
	day: any;
	hours: any;
	minutes: any;
	seconds: any;
	source = timer(0, 1000);


	//time oportuno
	_segundos = 1000;
	_minutos = this._segundos * 60;
	_horas = this._minutos * 60;
	_dias = this._horas * 24;
	fin: any;
	ahora: any;
	dias: any;
	horas: any;
	minutos: any;
	segundos: any;
	source1 = timer(0, 1000);

	//time egresado
	_segundos_egr = 1000;
	_minutos_egr = this._segundos_egr * 60;
	_horas_egr = this._minutos_egr * 60;
	_dias_egr = this._horas_egr * 24;
	fin_egr: any;
	ahora_egr: any;
	dias_egr: any;
	horas_egr: any;
	minutos_egr: any;
	segundos_egr: any;
	source2 = timer(0, 1000);


	url_loggin;



	targetDate: Date;
	interval: any;
	timeout: any;

	slides =
		[

			// {
			// 	img: '/assets/images/sorteo2024/banners/banner10.jpg', imdAw: "banner-text10.png", number: 1, active: true
			// },

			
			{
				 img: '/assets/images/sorteo2024/banners/banner-ganadoredes.jpg', img1: "left-win.png", img2: "right-win.png",number: 2, active: true
			},


			// {
			// 	place: "DEPARTAMENTO EN", title: "cancún", value: "Valor + de 10 millones ", name: 'BREZZA TOWERS MAR', img: '/assets/images/sorteo2024/bg-award1-vf.jpg', imdAw: "award1.png", number: 3, active: true
			// },

			// {
			// 	place: "DEPARTAMENTO EN", title: "edo. de méxico", value: "Valor + de 8 millones", name: 'BOSQUE REAL FIVE', img: '/assets/images/sorteo2024/bg-award2-vf.jpg', imdAw: "award2.png", number: 4, active: true
			// },
			// {
			// 	place: "CASA EN", title: "mérida", value: "Valor + de 6 millones ", name: 'EL ORIGEN', img: '/assets/images/sorteo2024/bg-award3-vf.jpg', imdAw: "award3.png", number: 5, active: true
			// },


		];



	slideConfig = {
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: true,
		fade: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: false,
					dots: true
				}
			},
		]
	};

	urlUtm = "";
	active = false;
	primerFecha: string = '2024-08-23T11:00:00'; // Primer fecha y hora
	segundaFecha: string = '2024-08-23T12:00:00'; // Segunda fecha y hora

	targetDate1: Date;
	targetDate2: Date;
	timeout1: any;
	timeout2: any;

	flagShow = false;
	flagShow2 = false;
	constructor(private _HomeService: HomeServiceService, private route: ActivatedRoute, private titleService: Title, private metaTagService: Meta,
		private router: Router) {
		if (localStorage.getItem('idColaborador') === null || localStorage.getItem('idColaborador') === "") {
		} else {
			this.session = true;
		}
		//this.setTargetDates();

		//console.log(this.slides);

		this.urlUtm = localStorage.getItem("urlUtm");

		this.route.queryParams.subscribe(params => {
			////console.log(window.location.href);
			this.idPayback = params['utm_source'];
			if (this.idPayback === "tradetracker") {
				this.arrayPB = this.linkPB.split("?");
				this.urlPB = "?" + this.arrayPB[1] + "&pb=true"
				localStorage.setItem("payback", this.urlPB);
				// //console.log("id = "  + this.idPayback);
				// //console.log("array = "  + this.urlPB);

			} else {
				this.urlPB = "";
				localStorage.setItem("payback", this.urlPB);
				////console.log("id vacio= "  + this.urlPB);
			}

			this.url_loggin = params['login'];
			if (this.url_loggin === "true" && !this.session) {
				(<any>jQuery('#loginmodal')).modal('show');
			} else if (this.url_loggin === "true" && this.session) {
				this.router.navigateByUrl('/menu-login');
			}
		});


	}


	// setTargetDates() {
	// 	// Convierte las fechas en formato string a objetos Date
	// 	this.targetDate1 = new Date(this.primerFecha);
	// 	this.targetDate2 = new Date(this.segundaFecha);
	// 	console.log("hola");
	// 	let now = new Date();

	// 	// Calcula el tiempo hasta la primera actualización
	// 	let timeUntilTarget1 = this.targetDate1.getTime() - now.getTime();

	// 	// Establece el timeout para la primera actualización
	// 	this.timeout1 = setTimeout(() => {
	// 		this.updateSlide1();
	// 	}, timeUntilTarget1);

	// 	// Calcula el tiempo hasta la segunda actualización
	// 	let timeUntilTarget2 = this.targetDate2.getTime() - now.getTime();

	// 	// Establece el timeout para la segunda actualización
	// 	this.timeout2 = setTimeout(() => {
	// 		this.updateSlide2();
	// 	}, timeUntilTarget2);
	// }


	// updateSlide1() {
	// 	//console.log("Actualiza el slide a las 11:00");
	// 	this.flagShow = true;
	// 	this.slides[0].text = "/egresados/icon-opt2.png";
	// 	this.slides[0].img = "/assets/images/sorteo2024/egresados/banner-sorteo-opt2.jpg";
	// 	this.slides[0].imdAw = "/egresados/textto-opt2.png";
	// 	//console.log("Contenido del primer objeto actualizado a las 18:40:", this.slides[0]);
	// 	const element = document.querySelector("#href1");
	// 	(element as HTMLElement).setAttribute('href', 'https://linktr.ee/sorteoanahuac.redesorganico');
	// 	const element2 = document.querySelector("#href2");
	// 	(element2 as HTMLElement).setAttribute('href', 'https://linktr.ee/sorteoanahuac.redesorganico');
	// }

	// updateSlide2() {
	// 	//console.log("Actualiza el slide a las 12:00");
	// 	this.slides[0].text = "/egresados/img-prox.png";
	// 	this.slides[0].img = "/assets/images/sorteo2024/egresados/banner-sorteo-egresados-transmision.jpg";
	// 	this.slides[0].imdAw = "/egresados/texto-prox.png";
	// 	//console.log("Contenido del primer objeto actualizado a las 17:00:", this.slides[0]);
	// 	const element = document.querySelector("#href1");
	// 	(element as HTMLElement).removeAttribute('href');

	// 	const element2 = document.querySelector("#href2");
	// 	(element2 as HTMLElement).removeAttribute('href');

	// }



	afterChange(e) {
		$('.slick-active .title-slide').addClass('animate__animated animate__fadeInLeft animate__delay-.5s d-block');
		$('.slick-active .img-award').addClass('animate__animated animate__fadeInRight animate__delay-.5s d-block');

		//window.scroll(0,0);
	}

	beforeChange(e) {
		$('.slick-active .title-slide').removeClass('animate__animated animate__fadeInLeft animate__delay-.5s d-block');
		$('.slick-active .img-award').removeClass('animate__animated animate__fadeInRight animate__delay-.5s d-block');
		//window.scroll(0,0);
	}

	updateFirstSlideImage(): void {
		const today = new Date();  // Fecha actual
		const targetDate =  new Date('11/21/2024 16:30')// Fecha objetivo - cambiar por cuatro de la tarde 21 de noviembre
		let distance_d = targetDate.getTime()   - today.getTime()   ;
		

		const targetDate2 =  new Date('11/20/2024 19:30')// Fecha objetivo - cambiar por cuatro de la tarde 21 de noviembre 19:30
		let distance_d2 = targetDate2.getTime()   - today.getTime()   ;
		
		console.log(distance_d);
		console.log(distance_d2)

		if(distance_d <= 0 && distance_d2 >= 0 ) {
			this.slides[0].img1 = `left-live.png`;
			this.slides[0].img2 = `right-live.png`;
			(<any>jQuery('#modal-message')).modal('show');
		}




		if(distance_d2 <= 0  ) {
			this.slides[0].img = `/assets/images/sorteo2024/banners/banner-proximamente.jpg`;
			this.slides[0].img1 = `left-prox.png`;
			this.slides[0].img2 = `right-prox.png`;
			(<any>jQuery('#modal-message')).modal('hide');
		}

		//console.log(targetDate);
		// Establece las horas, minutos, segundos y milisegundos de ambas fechas a 0
		//today.setHours(0, 0, 0, 0);
		//targetDate.setHours(0, 0, 0, 0);
	

	
	}

	ngOnInit(): void {

		//console.log("test");
		//this.updateFirstSlideImage();
		$(document).ready(function () {

			if ($('.slick-slide').hasClass('slick-active')) {
				$('.slick-active .title-slide').addClass('animate__animated animate__fadeInLeft animate__delay-.5s d-block');
				$('.slick-active .img-award').addClass('animate__animated animate__fadeInRight animate__delay-.5s d-block');
			} else {
				$('.slick-active .title-slide').removeClass('animate__animated animate__fadeInLeft animate__delay-.5s');
				$('.slick-active .img-award').removeClass('animate__animated animate__fadeInRight animate__delay-.5s');
			}


			$("#main-header .main-nav .site-navigation li .last-child").addClass("latido-egr");


		});


		this.titleService.setTitle(this.title);
		this.metaTagService.updateTag(
			{ name: 'title', content: this.title }
		);
		this.metaTagService.updateTag(
			{ name: 'description', content: 'Súmate y ganemos todos con Sorteo Anáhuac apoyando a miles de alumnos a construir un mejor futuro y al mismo tiempo ¡ganando increíbles premios!' }
		);

		this.metaTagService.updateTag(
			{ name: 'twitter:description', content: 'Súmate y ganemos todos con Sorteo Anáhuac apoyando a miles de alumnos a construir un mejor futuro y al mismo tiempo ¡ganando increíbles premios!' }
		);
		this.metaTagService.updateTag(
			{ property: 'og:description', content: 'Súmate y ganemos todos con Sorteo Anáhuac apoyando a miles de alumnos a construir un mejor futuro y al mismo tiempo ¡ganando increíbles premios!' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:title', content: this.title }
		);

		this.metaTagService.updateTag(
			{ property: 'keywords', content: '' }
		);



		this.metaTagService.updateTag(
			{ property: 'og:url', content: 'https://www.sorteoanahuac.mx/home' }
		);

		this.metaTagService.updateTag(
			{ property: 'og:image', content: 'https://www.sorteoanahuac.mx/assets/images/compartir2024.jpg' }
		);

		this.metaTagService.updateTag(
			{ name: 'twitter:title', content: this.title }
		);


		//time oportuno
		this.source1.subscribe(t => {
			this.ahora = new Date();
			this.fin = new Date('10/18/' + (this.ahora.getFullYear()) + ' 11:00');
			this.showDate();
		});

		//time magno
		this.source.subscribe(t => {
			this.now = new Date();
			this.end = new Date('11/21/' + (this.now.getFullYear()) + ' 17:00');
			this.showDateSorteoMagno();
		});

		//time egresado
		this.source2.subscribe(t => {
			this.ahora_egr = new Date();
			this.fin_egr = new Date('08/23/' + (this.ahora_egr.getFullYear()) + ' 10:00');
			this.showDateEgr();
		});


	}

	//sorteos oportunos
	showDate() {
		let distance = this.fin - this.ahora;
		if (this.fin - this.ahora <= 0) {
			distance = 0;
			// let counterSopt1 = document.querySelector("#counters-box-cta > div > div > div:nth-child(2) > div > div.counter-box") as HTMLElement;
			// if (counterSopt1) {
			// 	counterSopt1.style.display = "none";
			// }

			// let btnRedirect =document.querySelector("#counters-box-cta > div > div > div:nth-child(2) > div > div.btn-live") as HTMLElement;
			// if (btnRedirect) {
			// 	btnRedirect.style.display = "block";
			// }

			// const btnCard = document.querySelector("#sorteos > div > div > div:nth-child(2) > div > div.card-body > div:nth-child(7) > a") as HTMLAnchorElement;
			// if (btnCard) {
			// 	// Crear un nuevo botón de enlace
			// 	const newBtnCard = document.createElement('a');
			// 	newBtnCard.innerText = "!En vivo!";
			// 	newBtnCard.href = "https://www.facebook.com/events/426072950312806/";
			// 	newBtnCard.target = "_blank";
			// 	newBtnCard.className = btnCard.className; // Copiar las clases CSS para mantener el estilo

			// 	// Reemplazar el botón existente con el nuevo
			// 	btnCard.parentNode.replaceChild(newBtnCard, btnCard);
			// }

			// }else {
			// 	let btnRedirect =document.querySelector("#counters-box-cta > div > div > div:nth-child(2) > div > div.btn-live") as HTMLElement;
			// 	if (btnRedirect) {
			// 		btnRedirect.style.display = "none";
			// 	}
		}
		this.dias = Math.floor(distance / this._dias);
		if (this.dias < 10) this.dias = "0" + this.dias;

		this.horas = Math.floor((distance % this._dias) / this._horas);
		if (this.horas < 10) this.horas = "0" + this.horas;

		this.minutos = Math.floor((distance % this._horas) / this._minutos);
		if (this.minutos < 10) this.minutos = "0" + this.minutos;

		this.segundos = Math.floor((distance % this._minutos) / this._segundos);
		if (this.segundos < 10) this.segundos = "0" + this.segundos;

	}

	showDateSorteoMagno() {
		let distance = this.end - this.now;

		if (this.end - this.now <= 0) {
			distance = 0;
		}

		this.day = Math.floor(distance / this._day);
		if (this.day < 10) this.day = "0" + this.day;


		this.hours = Math.floor((distance % this._day) / this._hour);
		if (this.hours < 10) this.hours = "0" + this.hours;

		this.minutes = Math.floor((distance % this._hour) / this._minute);
		if (this.minutes < 10) this.minutes = "0" + this.minutes;

		this.seconds = Math.floor((distance % this._minute) / this._second);
		if (this.seconds < 10) this.seconds = "0" + this.seconds;

	}

	showDateEgr() {
		let distance = this.fin_egr - this.ahora_egr;
		if (this.fin_egr - this.ahora_egr <= 0) {
			distance = 0;
			this.flagShow2 = true;
		}
		this.dias_egr = Math.floor(distance / this._dias_egr);
		if (this.dias_egr < 10) this.dias_egr = "0" + this.dias_egr;

		this.horas_egr = Math.floor((distance % this._dias_egr) / this._horas_egr);
		if (this.horas_egr < 10) this.horas_egr = "0" + this.horas_egr;

		this.minutos_egr = Math.floor((distance % this._horas_egr) / this._minutos_egr);
		if (this.minutos_egr < 10) this.minutos_egr = "0" + this.minutos_egr;

		this.segundos_egr = Math.floor((distance % this._minutos_egr) / this._segundos_egr);
		if (this.segundos_egr < 10) this.segundos_egr = "0" + this.segundos_egr;

	}

	BtnStart() {
		this.start = true;
		this.getBoletoRandom();
		this.Msgvisible();
	}

	Msgvisible() {
		if (this.boleto == undefined && this.start == true) {
			var MsgBoletos = document.getElementById("Msg");
			// setTimeout(() =>{
			// 	if(this.boleto == undefined && this.start == true){
			// 	MsgBoletos.hidden = true;
			// 	}
			// },2500);
		}
	}

	girar() {
		let boleto = this.boleto.folio.toString();
		let numerosBoletos = boleto.split("");

		setTimeout(() => {

			for (let i = 0; i < 6; i++) {
				let number = numerosBoletos[i];
				let lis = "";
				let sizePx = 40;
				for (let index = 0; index <= parseInt(number); index++) {
					lis += "<li>" + index + "</li>";
				}

				$("#list-n" + i).append(lis);

				sizePx *= parseInt(number);
				sizePx = -760 - sizePx - 40;
				//console.log(sizePx);
				document.documentElement.style
					.setProperty('--my-start-px' + i, sizePx + "px");
				$("#list-n" + i).addClass("animation-list" + i);

			}
			setTimeout(() => {
				this.flag_buy_loader = false;
				this.flag_buy_btn = true;
				this.flag_buy = false;
			}, 5500);
		}, 100);


		this.flagGirar = true;


	}

	deleteNumbers(numerosBoletos) {
		for (let i = 0; i < 6; i++) {
			let number = numerosBoletos[i];
			for (let index = 0; index <= parseInt(number); index++) {
				$("#list-n" + i + " li:last").remove();
			}
			$("#list-n" + i).removeClass("animation-list" + i);
		}
	}



	getBoletoRandom() {
		this.metodo = true;
		this.boletos = [];
		this.boletosLuck = [];
		this.mensajeError = "";
		this.Error = false;
		this.boleto_disponible = 1;

		this.flag_buy = true;
		this.flag_buy_loader = true;
		this.flag_buy_btn = false;

		this._HomeService.getBoletoTablero().subscribe(
			data => {
				if (this.flagGirar) this.deleteNumbers(this.boleto.folio.toString().split(""));
				this.boleto = data[0]
				this.precio_boleto = data[0].costo;
				//console.log(this.boleto.folio);

				this.girar();

			},
			err => {
			}
		);

	}

	sendTicket() {
		// //console.log(this.boleto);
		if (this.boleto) {
			let sendTi = btoa(JSON.stringify(this.boleto));
			//const x = "https://sorteoanahuaccompra.sorteoanahuac.mx/pagar/5/" + sendTi + localStorage.getItem("payback");
			////console.log(x);
			//console.log("https://sorteoanahuaccompra.sorteoanahuac.mx/pagar/5/" + sendTi + localStorage.getItem("urlUtm"));
			window.open("https://sorteoanahuaccompra.sorteoanahuac.mx/pagar/5/" + sendTi, "_blank");
			// window.open("https://sorteoanahuaccompra.sorteoanahuac.mx/pagar/5/" + sendTi, "_blank"); 
			this.boleto = null;
			window.location.reload();

		}
		else {
			alert("Por favor genere un numero en la maquinita");
		}
	}


	sharedLink(id: any, mensaje: string) {
		this.link = document.location.origin;
		this.linkInput = document.location.origin;
		this.mensaje = mensaje;
		this.mensaje2 = mensaje.replace(/ /g, "%20");
		if (id == 1) {
			//this.link = document.location.href;
			this.link = this.link + "/premios-sorteo-anahuac";
			this.linkInput = this.linkInput + "/premios-sorteo-anahuac";
		} else if (id == 2) {
			//this.link = document.location.href;
			this.link = this.link + "/sorteo-oportuno";
			this.linkInput = this.linkInput + "/sorteo-oportuno";
		} else if (id == 3) {
			//this.link = document.location.href;
			this.link = this.link + "/remanentes";
			this.linkInput = this.linkInput + "/remanentes";
		}

	}

	clickbtn() {
		$("#link").select();
		document.execCommand("copy");
		//console.log("texto copiado");
		this.select = true;
	}
	more() {
		window.location.href = "/comparte-contenido";
	}

	linkCm() {
		window.location.href = "/comunicado-oficial-31-enero-2021";
	}
}
